import { ref } from 'vue'
import { axiosInstance } from '@/plugins/axios'
import Swal from 'sweetalert2'

export function usePaginatedFetchAndDownload() {
  const data = ref([])
  const isDownloading = ref(false)
  const error = ref(null)

  async function fetchDataPage(url, page) {
    const response = await axiosInstance.get(`${url}&page=${page}`)
    const pageData = response.data
    return pageData
  }

  function downloadCSV(csvContent, fileName = 'data.csv') {
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.download = fileName
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  function convertToCSV(arr) {
    if (arr.length === 0) return ''
    const csvRows = []
    const headers = Object.keys(arr[0])
    csvRows.push(headers.join(','))
    for (const row of arr) {
      const values = headers.map((header) => {
        const escaped = ('' + row[header]).replace(/"/g, '\\"')
        return `"${escaped}"`
      })
      csvRows.push(values.join(','))
    }
    return csvRows.join('\n')
  }

  async function fetchAllPagesAndDownload(url) {
    isDownloading.value = true
    error.value = null
    data.value = []

    try {
      const firstPageData = await fetchDataPage(url, 1)
      const totalPages = firstPageData.metadata.total_pages
      for (let page = 2; page <= totalPages; page++) {
        const pageData = await fetchDataPage(url, page)
        data.value = [...firstPageData.data, ...pageData.data]
      }
      const csvContent = convertToCSV(data.value)
      downloadCSV(csvContent)
      Swal.fire({
        icon: 'success',
        title: 'Report downloaded',
        text: 'Report has been downloaded successfully',
        showCloseButton: true,
      })
    } catch (e) {
      Swal.fire({
        icon: 'error',
        title: 'Download Failed',
        text: 'Something went wrong while downloading report.',
        showCloseButton: true,
      })
      error.value = e
    } finally {
      isDownloading.value = false
    }
  }

  return { fetchAllPagesAndDownload, isDownloading, error }
}


